import {
  Checkbox,
  Drawer,
  Grid,
  Icon,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';
import { IEtapa, IPlanosTemplate } from '../../../../commons/genericTypes';
import { IconDrawer } from '../../../../commons/styleds';

interface IDrawerTemplates {
  open: boolean;
  loading: boolean;
  onClose: Function;
  handleClick: Function;
  planosTemplates: IPlanosTemplate[];
}

const DrawerTemplates: React.FC<IDrawerTemplates> = (props) => {
  const { open, loading, onClose, handleClick, planosTemplates } = props;

  const columnsTemplate: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
    },
    {
      field: 'etapas',
      headerName: 'Etapas',
      width: 220,
      valueGetter: (value: any) => {
        const etapa = value as IEtapa[];
        const nomes = etapa?.map((etapas) => etapas.nome);
        const mostrar = nomes.join(' ; ');
        return mostrar;
      },
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      width: 90,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let PlanosTempletes = params.row as IPlanosTemplate;
        return (
          <Checkbox
            disabled
            onClick={() => HandleAtivoInativo(PlanosTempletes)}
            checked={PlanosTempletes.ativo}
          />
        );
      },
    },
  ];

  const HandleAtivoInativo = (planosTemplete: any) => {};

  return (
    <Drawer
      style={{ minWidth: '60vh' }}
      anchor={'right'}
      open={open}
      onClose={() => onClose(false)}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{
          padding: '90px 30px',
          maxWidth: '700px',
          width: '60vh',
        }}
      >
        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={2} md={2}>
            <IconDrawer onClick={() => onClose(false)}>close</IconDrawer>
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography variant="h5">{'Adicionar um Template ao Plano'}</Typography>
          </Grid>
        </Grid>

        <DataGrid
          getRowId={(row) => row?.id}
          onRowClick={(e) => handleClick(e)}
          rows={planosTemplates}
          columns={columnsTemplate}
          loading={loading}
          getRowHeight={() => 'auto'}
        />
      </Grid>
    </Drawer>
  );
};

export default DrawerTemplates;

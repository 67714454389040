import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Icon from '@mui/material/Icon';
import { GridColDef, GridCellParams } from '@mui/x-data-grid';
import { IEmpresaRelacionamento, IVaga } from '../../../commons/genericTypes/mercadoAlvo';

interface ColumnProps {
  canExclude: boolean;
  handleEditar: (obj: any) => void;
  handleDeletar: (obj: any) => void;
}

export const createColumns = ({
  canExclude,
  handleEditar,
  handleDeletar,
}: ColumnProps): GridColDef<IVaga>[] => [
  {
    field: 'id',
    headerName: 'Id',
    width: 80,
  },
  {
    field: 'titulo',
    headerName: 'Vaga/Título',
    width: 180,
  },
  {
    field: 'descricao',
    headerName: 'Descrição',
    width: 300,
  },
  {
    field: 'infoConfidencial',
    headerName: 'Inf. Confidencial',
    width: 180,
  },
  {
    field: 'cidade',
    headerName: 'Cidade',
    width: 120,
  },
  {
    field: 'estado',
    headerName: 'Estado',
    width: 120,
  },
  {
    field: 'pais',
    headerName: 'País',
    width: 120,
  },
  {
    field: 'dataInicio',
    headerName: 'Data Início',
    width: 120,
    renderCell: (params: GridCellParams) => {
      let obj = params.row;
      return new Date(obj.dataInicio!).toLocaleDateString();
    },
  },
  {
    field: 'dataTermino',
    headerName: 'Data Fim',
    width: 120,
    renderCell: (params: GridCellParams) => {
      let obj = params.row;
      return new Date(obj.dataTermino!).toLocaleDateString();
    },
  },
  {
    field: 'action',
    headerName: 'Ações',
    width: 100,
    editable: true,
    renderCell: (params: GridCellParams) => {
      const obj = params.row as any;
      return (
        <>
          <Icon sx={{ cursor: 'pointer' }} onClick={() => handleEditar(obj)}>
            <EditIcon />
          </Icon>
          {canExclude && (
            <Icon onClick={() => handleDeletar(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
              <DeleteIcon />
            </Icon>
          )}
        </>
      );
    },
  },
];

import React, { createContext, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../helpers/history';

interface PermissionsContextType {
  podeEditar: (modulo: string) => boolean;
  podeExcluir: (modulo: string) => boolean;
  isAdmin: () => boolean;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

export const PermissionsProvider: React.FC<{ children: any; modulo: string }> = ({
  children,
  modulo,
}) => {
  const user = useRecoilValue(authAtom);

  useEffect(() => {
    if (!modulo || user?.administrador) return;
    const hasAccess = user?.modulos?.some((m) => m.modulo === modulo);
    if (!hasAccess) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.goBack();
    }
  }, [modulo, user]);

  const podeEditar = (modulo: string): boolean => {
    if (user?.administrador) return true;
    const userModulo = user?.modulos?.find((m) => m.modulo === modulo);
    return ['EDICAO', 'EXCLUSAO'].includes(userModulo?.role ?? '');
  };

  const podeExcluir = (modulo: string): boolean => {
    if (user?.administrador) return true;
    const userModulo = user?.modulos?.find((m) => m.modulo === modulo);
    return ['EXCLUSAO'].includes(userModulo?.role ?? '');
  };

  const isAdmin = (): boolean => {
    return !!user?.administrador;
  };

  return (
    <PermissionsContext.Provider value={{ podeEditar, podeExcluir, isAdmin }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions deve ser usado dentro de um PermissionsProvider');
  }
  return context;
};

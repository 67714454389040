import { StatusCandidaturaEnum, TipoIndicacaoEnum } from '../enums';
import {
  IConfigArea,
  IConfigSegmento,
  IConfigSubArea,
  IContato,
  IdDescricao,
  IdNome,
  IIdioma,
  INivelDeCargo,
  ISetor,
  IUsuario,
} from '../genericTypes';

export enum EmpresaStatusEnum {
  Inativa = 'Inativa',
  Ativa = 'Ativa',
}

export interface INivelRelacionamento extends IdNome {
  hexadecimal: string;
}

export interface IEmpresaRelacionamento {
  id?: number;
  empresa?: string;
  contato?: string;
  agenteMercado?: string;
  ultimoContato?: Date;
  status?: EmpresaStatusEnum;
  idNivelRelacionamento?: number;
  idAgenteDeMercado?: number;
  idSegmento?: number;

  segmento?: IConfigSegmento;
  agenteDeMercado?: IUsuario;
  nivelRelacionamento?: INivelRelacionamento;
  setores?: any[];
}

export interface IEmpresaRelacionamentoSetores {
  id?: number;
  idEmpresaRelacionamento?: number;
  idSetor?: number;

  empresaRelacionamento?: IEmpresaRelacionamento;
  setor?: ISetor;
}

export interface IVaga extends IdDescricao {
  titulo?: string;
  descricao?: string;
  infoConfidencial?: string;
  emailRecrutador?: string;
  cidade?: string;
  estado?: string;
  pais?: string;
  dataInicio?: Date | null;
  dataTermino?: Date | null;
  ativo: boolean;
  dtaCadastro: Date;
  vagaRelacionamento?: string;
  meioCaptacao?: number;
  ma?: string;
  contato?: string;
  status?: EmpresaStatusEnum;
  usuarioCadastro?: number;

  idOrigem?: number;
  idFaixaSalarial?: number;
  idResponsavel?: number;
  idEmpresaRelacionamento?: number | null;

  ogirem?: IOrigemVaga;
  responsavel?: IUsuario;

  setores?: ISetor[];
  areas?: IConfigArea[];
  subAreas?: IConfigSubArea[];
  segmentos?: IConfigSegmento[];
  idiomas?: IIdioma[];
  niveisCargos?: INivelDeCargo[];
}

export interface IVagaSetor {
  id?: number;
  idVaga: number;
  idSetor: number;
}

export interface IVagaResponsavel {
  id?: number;
  idVaga: number;
  idResponsavel: number;
}

export interface IVagaQuantidade {
  vaga: string;
  quantidade: number;
}

export interface ISetorQuantidade {
  setor: string;
  quantidade: number;
}

export interface IVagasPorResponsavel {
  nome: string;
  quantidade: number;
}

export interface IOrigemVaga {
  id?: number;
  origem?: string;
}

export interface IVagaAreas {
  id?: number;
  idVaga: number;
  idArea: number;
}

export interface IVagaSubarea {
  id?: number;
  idVaga: number;
  idSubArea: number;
}

export interface IVagaContato {
  id?: number;
  vagaId?: number;
  contatoId?: number;
  data?: Date;
  aceito?: boolean;
  entrevista: boolean;
  realocado: boolean;
  defesaCandidatura?: string;
  motivoNegacao?: string;
  status?: StatusCandidaturaEnum;
  tipoIndicacao?: TipoIndicacaoEnum;
  contato?: IContato;
  candidato?: string;
}

export interface IMapaDeClientes {
  id: number;
  nome: string;
  ultimaIndicacao?: Date;
  qtdEnvios: number;
  pretensaoSalario: string;
  ultimoSalario: string;
  consultores: string[];
  areas: string[];
  subareas: string[];
  agentesMercado: string[];
  niveisCargo: string[];
  idiomas: string[];
  segmentos: string[];
  setores: string[];
}

import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';

export function useVagaActions() {
  const axiosWrapper = useAxiosWrapper();
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/vagas`;
  const baseActions = useBaseActions('vagas');

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    count: count,
  };

  async function count(
    analistas?: number[],
    setores?: number[],
    dataInicio?: Date,
    dataFim?: Date,
    limite?: number,
  ) {
    const params: Record<string, any> = {};

    if (analistas) params.analistas = analistas;
    if (setores) params.setores = setores;
    if (dataInicio) params.dataInicio = dataInicio.toISOString();
    if (dataFim) params.dataFim = dataFim.toISOString();
    if (limite !== undefined) params.limite = limite;

    const query = Object.entries(params)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`);
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');

    let url = `${baseUrl}/count`;
    const recursos = await axiosWrapper.get(url);

    return recursos;
  }
}

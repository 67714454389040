import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomTabPanel,
  a11yProps,
} from '../../../CRM/MiniBio/accordionAndTabPanel';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  IConfigArea,
  IConfigSegmento,
  IConfigSubArea,
  IFaixaSalarial,
  IIdioma,
  INivelDeCargo,
  ISetor,
  IUsuario,
} from '../../../../commons/genericTypes';
import {
  IEmpresaRelacionamento,
  IOrigemVaga,
  IVaga,
} from '../../../../commons/genericTypes/mercadoAlvo';
import {
  ButtonStato,
  GridTopPage,
  IconTituloStato,
  SelectStato,
} from '../../../../commons/styleds';
import { history } from '../../../../helpers/history';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { useConfigAreaActions } from '../../../../states/config/ConfiguracaoSistema/areas/configArea.actions';
import { useFaixaSalarialActions } from '../../../../states/config/ConfiguracaoSistema/faixasSalariais/faixaSalarial.actions';
import { useIdiomaConfigActions } from '../../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import { useNivelDeCargoActions } from '../../../../states/config/ConfiguracaoSistema/niveisDeCargos/nivelDeCargo.actions';
import { useConfigSegmentoActions } from '../../../../states/config/ConfiguracaoSistema/segmentos/configSegmento.actions';
import { useSetorActions } from '../../../../states/config/ConfiguracaoSistema/setores/setor.actions';
import { useConfigSubAreaActions } from '../../../../states/config/ConfiguracaoSistema/subAreas/configSubArea.actions';
import { useEmpresaRelacionamentoActions } from '../../../../states/mesaDeMercado/empresaRelacionamento/empresaRelacionamento.actions';
import { useOrigemVagasActions } from '../../../../states/mesaDeMercado/origemVagas/origemVagas.actions';
import { BasePage, Container } from '../../../CRM/Empresa';
import { IParams } from '../../../CRM/MiniBio/Endereco';
import LocalVaga from './LocalVaga';
import MultiSelect from '../../../../components/Generics/MultiSelect';
import { GridItemCadastroBtnSalvar } from '../../../CRM/Comanda';
import { styled } from 'styled-components';
import { useVagaActions } from '../../../../states/mesaDeMercado/vagas/vaga.actions';
import dayjs from 'dayjs';
import MultiSelectNome from '../../../../components/Generics/MultiSelectNome';
import InformacoesVaga from './informacoesVaga';
import GestaoProcesso from './gestaoProcesso';

const FollowUpDetalhes: React.FC = () => {
  const { id } = useParams<IParams>();
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Follow Up</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato onClick={() => history.goBack()} type="submit" variant="contained">
            <Icon>undo</Icon>
            Voltar
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            margin: '0px 25px !important',
          }}
        >
          <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Informações da Vaga" {...a11yProps(0)} />
            <Tab label="Gestão do Processo" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <InformacoesVaga />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <GestaoProcesso />
        </CustomTabPanel>
      </Box>
    </BasePage>
  );
};

export default FollowUpDetalhes;

import { Button, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import {
  GridContainer,
  GridItem,
  GridTopPage,
  GridTopPageHome,
  IconStato,
  IconTituloStato,
} from '../../commons/styleds';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { authAtom } from '../../states/config/CadastroUsuarios/usuarios/AuthState';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { history } from '../../helpers/history';
import Icon from '@mui/material/Icon';
import { MenuItens, MenuItensHome } from '../../commons/menuItens';
import Link from '@mui/material/Link';
import { IModulo } from '../../commons/genericTypes';
import { useUsuarioActions } from '../../states/config/CadastroUsuarios/usuarios/usuario.actions';

const Home: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const usuarioActions = useUsuarioActions();
  const [menuItens, setMenuItens] = useState<IModulo[]>([]);

  useEffect(() => {
    if (!auth || !auth?.token || !auth?.modulos) usuarioActions.logout();
    const modulos = auth?.modulos?.map((m) => m.modulo);
    const filterMenu = MenuItens.filter((m) => modulos?.includes(m.privilegio));
    setMenuItens(filterMenu);
  }, [auth]);

  return (
    <>
      <GridTopPageHome item xs={12} md={12}>
        <ButtonPortal variant="contained">Portal STATOGO</ButtonPortal>
        <ButtonAreaCliente variant="contained">Portal do Cliente</ButtonAreaCliente>
      </GridTopPageHome>

      <GridContainer id="home" container>
        <GridItem item xs={12} md={12}>
          <GridItemTituloAtalhos>Atalhos Rápidos</GridItemTituloAtalhos>
        </GridItem>
        <GridItem item xs={12} md={12} style={{ paddingBottom: '20px' }}>
          <GridItemAtalhos>
            <GridItem>
              <IconStatoAtalho>assessment</IconStatoAtalho>
            </GridItem>
            <GridItemTexto>
              Logs do <br />
              SISTEMA
            </GridItemTexto>
          </GridItemAtalhos>
          <GridItemAtalhos>
            <GridItem>
              <IconStatoAtalho>assessment</IconStatoAtalho>
            </GridItem>
            <GridItemTexto>
              Logs do <br />
              STATOGO
            </GridItemTexto>
          </GridItemAtalhos>
          <LinkStato onClick={() => history.push('/relatorios')}>
            <GridItemAtalhos>
              <GridItem>
                <IconStatoAtalho>insights</IconStatoAtalho>
              </GridItem>
              <GridItemTexto>Relatórios</GridItemTexto>
            </GridItemAtalhos>
          </LinkStato>
        </GridItem>
        <GridItem item xs={12} md={12}>
          <GridItemTituloAtalhos>Informações Gerais</GridItemTituloAtalhos>
        </GridItem>
        <GridItem item xs={12} md={12} sm={12}>
          {MenuItensHome.map((menuItem, index) => (
            <GridItem
              key={index}
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              style={{ paddingBottom: '50px' }}
            >
              <Link onClick={() => history.push(menuItem.linkHome as string)}>
                <GridItemHome>
                  <Typography color={'primary.main'} variant="body2">
                    <IconStato style={{}}>{menuItem.icon}</IconStato>
                  </Typography>
                  <Typography
                    style={{ textAlign: 'center', minWidth: '50px' }}
                    color={'primary.main'}
                    variant="body2"
                  >
                    {menuItem.nome}
                  </Typography>
                </GridItemHome>
              </Link>
            </GridItem>
          ))}
        </GridItem>
      </GridContainer>
    </>
  );
};

export const GridItemHome = styled(Grid)`
  cursor: pointer;
  border-radius: 39px;
  border: 3px solid var(--blue3);
  height: 150px;
  width: 150px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
`;

const GridItemAtalhos = styled(Grid)`
  cursor: pointer;
  margin-right: 25px;
  border-radius: 15px;
  border: 2px solid var(--blue3);
  height: 60px;
  width: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
`;

const LinkStato = styled(Link)`
  text-decoration: none !important;
`;

const GridItemTituloAtalhos = styled(Grid)`
  font-size: 25px;
  font-weight: 500;
`;

const GridItemTexto = styled(Grid)`
  color: var(--blue3);
  font-weight: 600;
`;

const IconStatoAtalho = styled(Icon)`
  margin-right: 15px;
  font-size: 40px !important;
  color: var(--blue3);
`;

const ButtonPortal = styled(Button)`
  border-radius: 10px !important;
  margin-right: 20px !important;
  color: var(--blue3);
`;

const ButtonAreaCliente = styled(Button)`
  border-radius: 10px !important;
  margin-right: 20px !important;
  background-color: var(--green2) !important;
`;

export default Home;

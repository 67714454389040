import { Controller, useFormContext } from 'react-hook-form';
import { GridItemCadastro, GridItemLinhaCadastro, Separador } from '..';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  createFilterOptions,
} from '@mui/material';
import { ButtonStato, TextFieldStato } from '../../../../commons/styleds';
import { useEmpresasActions } from '../../../../states/empresas/empresa.actions';
import { useEffect, useState } from 'react';
import { IEmpresa } from '../../../../commons/genericTypes';
import { toast } from 'react-toastify';

export interface IEmpresaType extends IEmpresa {
  inputValue?: string;
}

export const filter = createFilterOptions<IEmpresaType>();

export interface IEmpresaCliente {
  setEmpresaValue: Function;
  empresaValue?: string;
  label: string;
}

const EmpresaCliente: React.FC<IEmpresaCliente> = ({ setEmpresaValue, empresaValue, label }) => {
  const empresaActions = useEmpresasActions();
  const [empresas, setEmpresas] = useState<IEmpresaType[]>([]);
  const [open, toggleOpen] = useState(false);
  const [value, setValue] = useState<IEmpresaType | null>(null);
  const [dialogValue, setDialogValue] = useState({
    razaosocial: '',
    cnpj: '',
  });

  const handleClose = () => {
    setDialogValue({
      razaosocial: '',
      cnpj: '',
    });
    toggleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valueSubmit = {
      razaosocial: dialogValue.razaosocial,
      cnpj: dialogValue.cnpj,
    };
    empresaActions
      .create(valueSubmit)
      .then((data) => {
        setValue(data);
        toast.success('Empresa criada com sucesso');
        setEmpresaValue(data?.id);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Falha ao salvar nova Empresa');
      });

    handleClose();
  };

  useEffect(() => {
    empresaActions
      .list()
      .then((resp: any) => {
        setEmpresas(resp);
        if (empresaValue) {
          setValue(resp.find((e: any) => e.id == empresaValue));
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error('Falha ao buscar empresas');
      });
  }, []);

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                razaosocial: newValue,
                cnpj: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              razaosocial: newValue.inputValue,
              cnpj: '',
            });
          } else {
            setValue(newValue);
            setEmpresaValue(newValue?.id);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              razaosocial: `Adicionar: "${params.inputValue}"`,
              cnpj: '',
            });
          }

          return filtered;
        }}
        id="adicionar-empresa-dialog"
        options={empresas}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.razaosocial;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.razaosocial}</li>}
        fullWidth
        freeSolo
        renderInput={(params) => <TextFieldStato {...params} label={label} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Adicionar nova Empresa</DialogTitle>
          <DialogContent>
            <DialogContentText>Complete as informações da nova Empresa!</DialogContentText>
            <TextFieldStato
              autoFocus
              margin="dense"
              id="razaosocial"
              value={dialogValue.razaosocial}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  razaosocial: event.target.value,
                })
              }
              label="Razão Social"
              type="text"
            />
            <TextFieldStato
              margin="dense"
              id="cnpj"
              value={dialogValue.cnpj}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  cnpj: event.target.value,
                })
              }
              label="CNPJ"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <ButtonStato color="error" onClick={handleClose}>
              Cancelar
            </ButtonStato>
            <ButtonStato onClick={(e: any) => handleSubmit(e)}>Adicionar</ButtonStato>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EmpresaCliente;

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import styled from 'styled-components';
import { useState } from 'react';
import { history } from '../../helpers/history';

const BuscaContatos: React.FC = () => {
  const [textoBusca, setTextoBusca] = useState<string>('');

  const submitSearch = (e: any) => {
    e.preventDefault();
    history.push(`/crm/contatos?busca=${textoBusca}`);
  };

  return (
    <PaperBusca component="form" sx={{ p: '2px 4px' }} onSubmit={submitSearch}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Pesquisar Contatos"
        inputProps={{ 'aria-label': 'pesquisar contatos' }}
        onChange={(e) => setTextoBusca(e.target.value)}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </PaperBusca>
  );
};

const PaperBusca = styled(Paper)`
  display: flex;
  align-items: center;
  width: 360px;
  border-radius: 17px !important;

  @media (max-width: 600px) {
    display: none;
  }
`;

export default BuscaContatos;

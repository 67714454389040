import { useBaseActions } from '../base.actions';

function useRedefinirSenhaActions() {
  const baseActions = useBaseActions('redefinicaoSenha');

  return {
    create: baseActions.create,
    update: baseActions.update,
    get: baseActions.get,
  };
}

export { useRedefinirSenhaActions };

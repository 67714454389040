import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  LocalizationProvider,
  DateTimePicker,
  DesktopDatePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import { usePermissions } from '../../../../../components/PermissionsProvider';
import { IComanda, IComandaEtapaReunioes, IConsultor } from '../../../../../commons/genericTypes';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useComandaEtapaReuniaoActions } from '../../../../../states/config/Programas/comandaEtapaReunioes/comandaEtapaReuniao.actions';
import { toast } from 'react-toastify';

interface IOnboardingProps {
  consultorOnboardings: IConsultor[];
  comanda: IComanda;
}

const Onboarding: React.FC<IOnboardingProps> = (props) => {
  const { consultorOnboardings, comanda } = props;
  const [dadosOnboarding, setDadosOnboarding] = useState<IComandaEtapaReunioes | undefined>(
    comanda.comandaEtapaReunioes?.find((f: any) => f.onboarding),
  );
  const actions = useComandaEtapaReuniaoActions();
  const { isAdmin } = usePermissions();
  const admin = isAdmin();
  const [open, setOpen] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      dtPrevista: dadosOnboarding?.dtPrevista,
      dtRealizadaInicio: dadosOnboarding?.dtRealizadaInicio,
      dtRealizadaFim: dadosOnboarding?.dtRealizadaFim,
    },
  });

  useEffect(() => {
    reset({
      dtPrevista: dadosOnboarding?.dtPrevista,
      dtRealizadaInicio: dadosOnboarding?.dtRealizadaInicio,
      dtRealizadaFim: dadosOnboarding?.dtRealizadaFim,
    });
  }, [dadosOnboarding]);

  const getTempoTotal = (horaInicio: string, horaFim: string) => {
    let horaTotal = '';
    let hInicio = parse(horaInicio);
    let hFim = parse(horaFim);

    let diff = Math.abs(hFim - hInicio);
    if (diff !== 0) {
      let horas = Math.floor(diff / 60);
      let minutos = diff - horas * 60;
      horaTotal = horas + ':' + (minutos > 9 ? minutos : '0' + minutos);
    }

    return horaTotal;
  };

  const parse = (horario: any) => {
    let [hora, minuto] = horario.split(':').map((v: any) => parseInt(v));
    if (!minuto) minuto = 0;
    return minuto + hora * 60;
  };

  const editHorario = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    const dados = {
      ...dadosOnboarding,
      dtPrevista: data.dtPrevista,
      dtRealizadaInicio: data.dtRealizadaInicio,
      dtRealizadaFim: data.dtRealizadaFim,
    };
    actions
      .update(dados.id!, dados)
      .then((resp) => {
        toast.success('Salvo com sucesso');
        setDadosOnboarding(dados);
      })
      .catch((e) => toast.error('Falha ao atualizar onboarding'))
      .finally(() => setOpen(false));
  };

  return (
    <>
      <Grid item xs={12} sm={4} md={2} lg={1.5}>
        <Typography variant="h6" component="h6">
          Onboarding:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={10} sx={{ display: 'flex' }}>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Consultor:
          </Typography>
          <Typography variant="subtitle1">
            {
              consultorOnboardings.find((f: IConsultor) => f.id === comanda?.idConsultorOnboarding)
                ?.nome
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Data:
          </Typography>
          <Typography variant="subtitle1">
            {new Date(dadosOnboarding?.dtPrevista?.toString()!).toLocaleDateString()}
          </Typography>
          <Typography variant="subtitle1">
            {'De ' +
              new Date(dadosOnboarding?.dtRealizadaInicio!.toString()!)
                .toLocaleTimeString()
                .substring(0, 5)}
          </Typography>
          <Typography variant="subtitle1">
            {'Até ' +
              new Date(dadosOnboarding?.dtRealizadaFim!.toString()!)
                .toLocaleTimeString()
                .substring(0, 5)}
          </Typography>
          <Typography variant="subtitle1">
            {'Total ' +
              getTempoTotal(
                new Date(dadosOnboarding?.dtRealizadaInicio!.toString()!).toLocaleTimeString(),
                new Date(dadosOnboarding?.dtRealizadaFim!.toString()!).toLocaleTimeString(),
              )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Status:
          </Typography>
          <Typography variant="subtitle1">{dadosOnboarding?.status}</Typography>
        </Grid>
        {admin && (
          <Grid item xs={12} md={2}>
            <Button onClick={editHorario} variant="outlined" sx={{ fontSize: '10px' }}>
              Editar Onboarding
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Onboarding</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="dtPrevista"
                control={control}
                render={({ field }) => (
                  <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{
                        clearButtonLabel: 'Empty',
                        todayButtonLabel: 'Now',
                      }}
                    >
                      <DesktopDatePicker
                        value={field.value ? dayjs(field.value) : null}
                        label="Data Prevista"
                        format="DD/MM/YYYY"
                        timezone={'UTC'}
                        defaultValue={field.value ? dayjs(field.value) : null}
                        onChange={(value: any) => {
                          field.onChange(value.toDate());
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              />
              <Controller
                name="dtRealizadaInicio"
                control={control}
                render={({ field }) => (
                  <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                      >
                        <DemoItem label={'Hora Início'}>
                          <TimePicker
                            sx={{ width: '100% !important' }}
                            views={['hours', 'minutes']}
                            format="HH:mm"
                            label="Hora Início"
                            ampm={false}
                            ampmInClock={false}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(newValue) => {
                              const localDate = newValue
                                ? dayjs.tz(newValue, 'America/Sao_Paulo')
                                : null;
                              field.onChange(localDate ? localDate.format() : null);
                            }}
                            slotProps={{
                              textField: {
                                required: true,
                                fullWidth: true,
                                variant: 'outlined',
                              },
                            }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                )}
              />
              <Controller
                name="dtRealizadaFim"
                control={control}
                render={({ field }) => (
                  <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                      >
                        <DemoItem label={'Hora Fim'}>
                          <TimePicker
                            sx={{ width: '100% !important' }}
                            views={['hours', 'minutes']}
                            format="HH:mm"
                            label="Hora Fim"
                            ampm={false}
                            ampmInClock={false}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(newValue) => {
                              const localDate = newValue
                                ? dayjs.tz(newValue, 'America/Sao_Paulo')
                                : null;
                              field.onChange(localDate ? localDate.format() : null);
                            }}
                            slotProps={{
                              textField: {
                                required: true,
                                fullWidth: true,
                                variant: 'outlined',
                              },
                            }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Onboarding;

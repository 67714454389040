import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';
import { contatoAtom } from './ContatoState';

function useContatoActions() {
  const baseActions = useBaseActions('contatos', contatoAtom);
  const axiosWrapper = useAxiosWrapper();

  return {
    list: list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    patch: baseActions.patch,
  };

  async function list(pesquisa?: string | null, resumido: boolean = false) {
    try {
      let url = `${process.env.REACT_APP_API_URL}/v1/contatos?resumido=${resumido}`;
      if (pesquisa) url += `&TextoBusca=${pesquisa}`;
      const recursos = await axiosWrapper.get(url);
      return recursos;
    } catch (e: any) {
      return [];
    }
  }
}

export { useContatoActions };

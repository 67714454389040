import { Autocomplete, Box, Grid, Icon, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { styled } from 'styled-components';
import { ButtonStato } from '../../../../commons/styleds';
import { Container } from '../../../CRM/Empresa';
import TabelaIndicados from './tabelaIndicados';

const GestaoProcesso: React.FC = () => {
  const [indicacaoContato, setIndicacaoContato] = useState<string>('');
  const [contatosIndicados, setContatosIndicados] = useState<any[]>([]);

  return (
    <>
      <Container container gap={1} rowSpacing={1} sx={{ paddingTop: 0 }}>
        <Grid item xs={12}>
          <Typography variant="h4">Recrutamento</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} gap={3} alignItems="flex-start">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <DivIcon>
                  <Icon>filter_alt</Icon>
                </DivIcon>
                <Typography variant="body1">
                  <b>Assessorados Sugeridos</b> (Automático pelo Matching do Sistema)
                </Typography>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}
              >
                <DivIcon>
                  <Icon>person</Icon>
                </DivIcon>
                <Typography variant="body1">
                  <b>Assessorados Indicados</b> (Input manual do Agente ou Consultor)
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Assessorados</Typography>
        </Grid>
        <Grid item xs={12}>
          <TabelaIndicados />
        </Grid>
      </Container>
    </>
  );
};

export const DivIcon = styled.div`
  padding: 7px 11px;
  box-shadow: 0px 0px 4px 0px #ccc;
  width: 35px;
  display: flex;
  justify-content: center;
  border-radius: 25%;
  background: #f1f1f1;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--blue3);
  }
`;

export default GestaoProcesso;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Autocomplete, Grid } from '@mui/material';

type Location = {
  id: string;
  nome: string;
};

const LocalVaga = () => {
  const { control, watch } = useFormContext();
  const paisValue = watch('pais');
  const estadoValue = watch('estado');

  const [paises, setPaises] = useState<string[]>([]);
  const [estados, setEstados] = useState<{ id: number; nome: string }[]>([]);
  const [cidades, setCidades] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/paises?orderBy=nome')
      .then((response) => {
        const nomesPaises = response.data.map((pais: any) => pais.nome);
        setPaises(nomesPaises);
      })
      .catch((error) => console.error('Error fetching countries', error));
  }, []);

  useEffect(() => {
    if (paisValue === 'Brasil') {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
        .then((response) => {
          setEstados(response.data); // Armazena objetos com id e nome
        })
        .catch((error) => console.error('Error fetching states', error));
    } else {
      setEstados([]);
    }
  }, [paisValue]);

  useEffect(() => {
    if (estadoValue && paisValue === 'Brasil') {
      const estadoSelecionado = estados.find(
        (estado) => estado.nome.toLowerCase() === estadoValue.toLowerCase(),
      );
      if (estadoSelecionado) {
        const estadoId = estadoSelecionado.id;
        axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios?orderBy=nome`,
          )
          .then((response) => {
            const nomesCidades = response.data.map((cidade: any) => cidade.nome);
            setCidades(nomesCidades);
          })
          .catch((error) => console.error('Error fetching cities', error));
      } else {
        setCidades([]);
      }
    } else {
      setCidades([]);
    }
  }, [estadoValue, estados, paisValue]);

  return (
    <Grid container rowSpacing={2} columnSpacing={1} sx={{ marginTop: '10px' }}>
      <Grid item xs={12} md={4}>
        <Controller
          name="pais"
          control={control}
          render={({ field }) => (
            <Autocomplete
              freeSolo
              options={paises}
              inputValue={field.value || ''}
              onInputChange={(_, newInputValue) => field.onChange(newInputValue)}
              renderInput={(params) => <TextField {...params} label="País" />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Controller
          name="estado"
          control={control}
          render={({ field }) => (
            <Autocomplete
              freeSolo
              options={estados}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.nome)}
              inputValue={field.value || ''}
              onInputChange={(_, newInputValue) => field.onChange(newInputValue)}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.nome}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Estado" />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Controller
          name="cidade"
          control={control}
          render={({ field }) => (
            <Autocomplete
              freeSolo
              options={cidades}
              inputValue={field.value || ''}
              onInputChange={(_, newInputValue) => field.onChange(newInputValue)}
              renderInput={(params) => <TextField {...params} label="Cidade" />}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default LocalVaga;

import { comandaEtapasReuniaoAtom } from './ComandaEtapaReuniaoState';
import { useBaseActions } from '../../../base.actions';
import { useAxiosWrapper } from '../../../../services/axiosWrapper';

function useComandaEtapaReuniaoActions() {
  const recurso = 'comandaEtapasReuniao';
  const baseActions = useBaseActions(recurso, comandaEtapasReuniaoAtom);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/${recurso}`;
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    revisar: revisar,
  };

  async function revisar(id: string | number, obj: object | any) {
    const recurso = await axiosWrapper.put(`${baseUrl}/${id}/revisar`, obj);
    return recurso;
  }
}

export { useComandaEtapaReuniaoActions };

import { useEffect, useState } from 'react';
import { useRelatoriosActions } from '../../../../states/relatorios/base/relatorios.actions';
import { toast } from 'react-toastify';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PaperRelatorio } from '..';
import moment from 'moment';
import { ptBR } from '@mui/material/locale';

interface IHistoricoTimesheet {
  id: number;
  consultor: string;
  dataInicio: Date;
  dataFim: Date;
  status: string;
  dataGerado: Date;
}

const HistoricoTimesheetConsultor: React.FC = () => {
  const relatoriosActions = useRelatoriosActions();
  const [historicos, setHistoricos] = useState<IHistoricoTimesheet[]>([]);

  useEffect(() => {
    relatoriosActions
      .list()
      .then((data: any) => {
        const historico: IHistoricoTimesheet[] = data.map((relatorio: any) => {
          const dadosEntrada = JSON.parse(relatorio.dadosEntrada);
          return {
            id: relatorio.id,
            consultor: dadosEntrada.consultorNome,
            dataInicio: new Date(dadosEntrada.dataInicio),
            dataFim: new Date(dadosEntrada.dataFim),
            status: relatorio.status,
            dataGerado: relatorio.dataGerado,
          };
        });
        setHistoricos(historico);
      })
      .catch((e) => toast.error(e));
  }, []);

  const downloadArquivo = (id: number) => {
    relatoriosActions
      .get(id)
      .then((resp: any) => {
        window.open(resp.linkDownload, '_blank', 'noopener,noreferrer');
      })
      .catch((e) => toast.error(e));
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
    },
    {
      field: 'consultor',
      headerName: 'Consultor',
      width: 180,
    },
    {
      field: 'dataInicio',
      headerName: 'Data Início',
      width: 120,
      valueGetter: (params: Date) => {
        const data = params as Date;
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      field: 'dataFim',
      headerName: 'Data Fim',
      width: 120,
      valueGetter: (params: Date) => {
        const data = params as Date;
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
    },
    {
      field: 'dataGerado',
      headerName: 'Data Gerado',
      width: 120,
      valueGetter: (params: Date) => {
        const data = params as Date;
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      field: '',
      headerName: 'Ações',
      width: 80,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let relatorioId = params.row.id as number;
        return (
          <>
            <span
              style={{ display: 'flex', alignItems: 'flex-end', color: 'rgb(70, 70, 70)' }}
              onClick={() => downloadArquivo(relatorioId)}
            >
              <FileDownloadIcon />
            </span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <PaperRelatorio sx={{ marginTop: '30px' }} elevation={3}>
        <h3>Histórico: </h3>
        <Box
          sx={{
            height: '30vh',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              rows={historicos}
              columns={columns}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
            />
          </ThemeProvider>
        </Box>
      </PaperRelatorio>
    </>
  );
};

export default HistoricoTimesheetConsultor;

import { styled } from 'styled-components';
import {
  Box,
  Grid,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Icon,
  FormControl,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ButtonStato, GridContainer, TextFieldStato } from '../../../../../commons/styleds';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { history } from '../../../../../helpers/history';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Container } from '../..';
import { IUsuarioEmpresa } from '../../../../../commons/genericTypes';
import { FormStato } from '../../../../FerramentasAdministrativas/CadastroUsuarios/Consultores';
import { useUsuariosEmpresasActions } from '../../../../../states/empresas/usuarios/usuarioEmpresa.actions';
import { useParams } from 'react-router-dom';
import { IParams } from '../../../MiniBio/Endereco';

const empty: IUsuarioEmpresa = {
  id: 0,
  empresaId: 0,
  nome: '',
  sobrenome: '',
  email: '',
  cargo: '',
};

const UsuariosEmpresa: React.FC = () => {
  const { id } = useParams<IParams>();
  const methods = useForm<IUsuarioEmpresa>();
  const actions = useUsuariosEmpresasActions();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [idAtualizar, setIdAtualizar] = useState(0);
  const [usuarios, setUsuarios] = useState<IUsuarioEmpresa[]>([]);

  useEffect(() => {
    actions
      .list(false, `?empresaId=${id}`)
      .then((data) => setUsuarios(data))
      .finally(() => setLoading(false));
  }, []);

  const atualizarUsuario = (data: IUsuarioEmpresa) => {
    data.empresaId = Number(id);
    actions
      .update(data.id!, data)
      .then((resp) => {
        toast.success('Atualizado com sucesso');
        const novos = usuarios.filter((u) => u.id !== data.id);
        novos.push(resp);
        setUsuarios(novos);
      })
      .finally(() => setLoadingForm(false));
  };

  const criarUsuario = (data: IUsuarioEmpresa) => {
    data.empresaId = Number(id);
    actions
      .create(data)
      .then((resp) => {
        toast.success('Criado com sucesso');
        const novos = [...usuarios, data];
        setUsuarios(novos);
      })
      .catch((err) => {
        toast.error('Erro ao criar');
      })
      .finally(() => setLoadingForm(false));
  };

  const onSubmit = async (data: IUsuarioEmpresa) => {
    setLoadingForm(true);
    if (data.id! > 0) atualizarUsuario(data);
    else criarUsuario(data);
  };

  const cancelar = () => {
    methods.reset(empty);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    setLoadingForm(true);
    methods.reset(params.row, { keepDefaultValues: true });
    setIdAtualizar(params.row.id);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const handleDelete = (id: number) => {
    actions.delete(id).then(() => toast.success('Removido com sucesso'));
  };

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
    },
    {
      field: 'sobrenome',
      headerName: 'Sobrenome',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 220,
      editable: true,
    },
    {
      field: 'action',
      headerName: 'Ações',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IUsuarioEmpresa;
        return (
          <Icon onClick={() => handleDelete(obj.id!)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  return (
    <>
      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id="formArea" onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>{idAtualizar ? `Atualizar Usuário - ID: ${idAtualizar}` : 'Novo Usuário'}</h3>

                <React.Fragment>
                  <Box>
                    <Controller
                      control={methods.control}
                      name="nome"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl
                          id="nome"
                          required
                          fullWidth
                          variant="outlined"
                          sx={{ marginTop: '20px' }}
                        >
                          <TextFieldStato
                            {...field}
                            id={'nomeInput'}
                            type="text"
                            inputRef={ref}
                            label="Nome"
                          />
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={methods.control}
                      name="sobrenome"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl
                          id="sobrenome"
                          required
                          fullWidth
                          variant="outlined"
                          sx={{ marginTop: '20px' }}
                        >
                          <TextFieldStato
                            {...field}
                            id={'sobrenomeInput'}
                            type="text"
                            inputRef={ref}
                            label="Sobrenome"
                          />
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={methods.control}
                      name="email"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl
                          id="email"
                          required
                          fullWidth
                          variant="outlined"
                          sx={{ marginTop: '20px' }}
                        >
                          <TextFieldStato
                            {...field}
                            id={'emailInput'}
                            type="email"
                            inputRef={ref}
                            disabled={idAtualizar > 0}
                            label="Email"
                          />
                        </FormControl>
                      )}
                    />

                    {!idAtualizar && (
                      <>
                        <Controller
                          control={methods.control}
                          name="senha"
                          render={({ field }) => (
                            <FormControl
                              required
                              fullWidth
                              variant="outlined"
                              sx={{ marginTop: '20px' }}
                            >
                              <TextFieldStato {...field} type="password" label="senha" />
                            </FormControl>
                          )}
                        />

                        <Controller
                          control={methods.control}
                          name="confirmarSenha"
                          render={({ field }) => (
                            <FormControl
                              required
                              fullWidth
                              variant="outlined"
                              sx={{ marginTop: '20px' }}
                            >
                              <TextFieldStato {...field} type="password" label="Confirmar Senha" />
                            </FormControl>
                          )}
                        />
                      </>
                    )}
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <ButtonStato
                      color="error"
                      variant="contained"
                      sx={{ marginRight: '10px' }}
                      onClick={cancelar}
                    >
                      {'Cancelar'}
                    </ButtonStato>
                    <ButtonStato type="submit" variant="contained">
                      {'Salvar'}
                    </ButtonStato>
                  </Box>
                </React.Fragment>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row: any) => row.id + row.email}
                onRowClick={handleRowClick}
                rows={usuarios}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default UsuariosEmpresa;

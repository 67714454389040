import { FormControl, Grid, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IFaixaSalarial } from '../../../../commons/genericTypes';
import { useFaixaSalarialActions } from '../../../../states/config/ConfiguracaoSistema/faixasSalariais/faixaSalarial.actions';
import { SelectStato } from '../../../../commons/styleds';
// import { useFaixasSalariaisActions } from '../../../states/faixasSalariais/faixaSalarial.actions';

interface Props {
    novoUsuario: boolean;
}

const PPCStepper1: React.FC<Props> = (props: Props) => {
    const { novoUsuario } = props;

    const faixaSalarialActions = useFaixaSalarialActions();
    const [faixasSalariais, setFaixasSalariais] = useState<IFaixaSalarial[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const methods = useFormContext();
    // const methods = useForm({ defaultValues: empty });
    // const methods = useForm();

    useEffect(() => {

        faixaSalarialActions
            .list()
            .then((resp: IFaixaSalarial[]) => {
                setFaixasSalariais(resp);
                setTimeout(() => {
                    setLoading(true);
                }, 1000);
            })
            .catch((err: any) => toast.warn(err));

    }, []);

    return (
        <>
            <Grid container xs={12} md={12} sm={12} sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
            }}>
                <Grid xs={12} md={12} sm={12}>
                    <Typography sx={{ padding: "10px 0px" }} variant="h5" component="h2">
                        Informações Gerais
                    </Typography>
                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP1'
                        render={({ field: { ref, ...field } }) => (<>
                            <InputLabel sx={{
                                whiteSpace: "normal", fontWeight: "700"
                            }}>
                                Considerando suas despesas e planejamento financeiro, por quanto tempo você pode manter uma situação financeira confortável?
                            </InputLabel >

                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />
                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Descreva seu último pacote de remuneração, incluindo benefícios como variáveis, ajudas de custo, plano de saúde, vale alimentação, transporte etc..
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP2'
                        render={({ field: { ref, ...field } }) => (
                            <TextField sx={{ width: "100%"}}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}                                
                            />
                        )}
                    />
                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700",
                        marginBottom: "10px"
                    }}>
                        Qual a faixa de remuneração mínima que você aceita para participar de um processo seletivo?
                    </InputLabel >
                    {loading &&
                        <Controller
                            control={methods.control}
                            name='idFaixaRemuneracao'
                            disabled
                            render={({ field: { ref, ...field } }) => (
                                <FormControl fullWidth>
                                    <SelectStato {...field} required>
                                        {faixasSalariais.map((statu: IFaixaSalarial, index: number) => (
                                            <MenuItem value={statu.id} key={index}>
                                                <b>{statu.nome}</b>
                                            </MenuItem>
                                        ))}
                                    </SelectStato>
                                </FormControl>
                            )}
                        />
                    }
                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Você tem disponibilidade para viagens ou mudanças? Se sim, especifique a região desejada.
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP3'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Que transição profissional você esta buscando?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP4'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Em quais áreas/segmentos você possui mais experiência?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP5'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Quais áreas/segmentos despertam seu interesse para trabalhar?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP6'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Você está satisfeito com o progresso da sua carreira? Por quê?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP7'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Quais dificuldades e desafios você imagina enfrentar durante sua transição?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP8'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Há interesse em Empreendedorismo, Conselho, Consultoria, Vida Acadêmica entre outros?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP9'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Em caso afirmativo, esse plano é de curto, médio ou longo prazo?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP10'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Você tem disponibilidade para mudanças? Se Sim para onde?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP11'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>

                <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
                    <InputLabel sx={{
                        whiteSpace: "normal", fontWeight: "700"
                    }}>
                        Você possui conhecimento em outro idioma?
                    </InputLabel >
                    <Controller
                        control={methods.control}
                        name='informacaoGeralP12'
                        render={({ field: { ref, ...field } }) => (<>
                            <TextField sx={{ width: "100%" }}
                                id="standard-basic"
                                {...field}
                                inputRef={ref}
                                label=""
                                variant="standard"
                                disabled
                                multiline
                                maxRows={4}
                                inputProps={{style: {fontWeight: "700"}}}
                            />
                        </>)} />

                </Grid>
            </Grid>
        </>
    );
};

export default PPCStepper1;

import { FormControl, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

interface Props {
  novoUsuario: boolean;
}

const PPCStepper5: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Grid container xs={12} md={12} sm={12} sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}>
        <Grid xs={12} md={12} sm={12}>
          <Typography sx={{ padding: "10px 0px" }} variant="h5" component="h2">
            Reflexão de carreira
          </Typography>
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Quais são meus principais interesses e paixões?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP1'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Quais são meus valores e princípios mais importantes?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP2'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}

                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            O que eu gosto e não gosto no meu trabalho atual ou em trabalhos anteriores?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP3'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Quais são as suas principais competências?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP4'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Qual seu posicionamento profissional?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP5'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Quais são as minhas prioridades em termos de equilíbrio entre vida pessoal e profissional?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP6'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Quais habilidades adicionais eu preciso desenvolver para avançar na minha carreira?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP7'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            O que eu mudaria na minha carreira até agora, se pudesse?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP8'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal", fontWeight: "700"
          }}>
            Como posso continuar a me motivar e me engajar no meu trabalho?
          </InputLabel >
          <Controller
            control={methods.control}
            name='reflexaoDeCarreiraP9'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
                inputProps={{style: {fontWeight: "700"}}}
              />
            )}
          />
        </Grid>
      </Grid>

    </>
  );
};

export default PPCStepper5;

import { useRecoilValue } from 'recoil';
import { useUsuarioActions } from '../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { authAtom } from '../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logo from './../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-1.png';

import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FormControlStato, GridContainer, GridItem, Title } from '../../commons/styleds';
import { styled } from 'styled-components';
import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { history } from '../../helpers/history';
import { TitleLogin } from '../Login';
import { useRedefinirSenhaActions } from '../../states/redefinirSenha/redefinirSenha.actions';

const SolicitarRedefinicaoSenha: React.FC = () => {
  const redefinirSenhaActions = useRedefinirSenhaActions();
  const [email, setEmail] = useState<string>('');
  const [enviado, setEnviado] = useState<boolean>(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    redefinirSenhaActions.create({ email, origem: 'ONE' }).then((resp: any) => {
      toast.success('Enviado com sucesso.');
      setEnviado(true);
    });
  };

  return (
    <GridContainer container>
      <GridItem item xs={12} md={6}>
        <img src={Logo} alt="logo" width={'80%'} />
      </GridItem>
      <GridItem item xs={12} md={6} color={'var(--blue2)'}>
        <Grid item xs={10} md={9}>
          <form onSubmit={onSubmit}>
            <GridItem item xs={12} md={12} color={'var(--blue2)'}>
              <TitleLogin>Redefinição de senha</TitleLogin>
              <FormControlStato sx={{ width: '100%', marginBottom: '15px' }} variant="filled">
                <TextField
                  id="filled-basic"
                  label="E-mail"
                  type="email"
                  required
                  variant="filled"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                />
              </FormControlStato>
              {enviado && (
                <Typography variant="body1" color="var(--green3)">
                  Verifique sua caixa de entrada para obter o link de redefinição de senha.
                </Typography>
              )}
            </GridItem>
            <GridItem item xs={12} md={12} color={'var(--blue2)'} style={{ paddingTop: '15px' }}>
              <Grid style={{ textAlign: 'right', width: '100%' }}>
                <Button
                  variant="outlined"
                  style={{ marginRight: '15px' }}
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </Button>
                <Button type="submit" variant="contained">
                  Enviar
                </Button>
              </Grid>
            </GridItem>
          </form>
        </Grid>
      </GridItem>
    </GridContainer>
  );
};

export default SolicitarRedefinicaoSenha;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAnexosActions } from '../../../../states/crm/contatoAuxiliares/anexos/anexos.actions';
import { IParams } from '../Endereco';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridColDef,
  GridSlots,
} from '@mui/x-data-grid';
import InputArquivo from '../../../../components/InputArquivo/InputArquivo';
import { useRecoilState } from 'recoil';

interface EditToolbarProps {
  onAddClick: () => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { onAddClick } = props;

  return (
    <Box sx={{ padding: 1 }}>
      <Button color="primary" startIcon={<AddIcon />} onClick={onAddClick}>
        Adicionar Novo Anexo
      </Button>
    </Box>
  );
}

const Anexo: React.FC = () => {
  const { id } = useParams<IParams>();
  const anexosActions = useAnexosActions();
  const [anexos, setAnexos] = useState<any[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [novoArquivo, setNovoArquivo] = useState<any>({ anexo: '' } as any);
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');

  useEffect(() => {
    anexosActions
      .list(false, `?contatoId=${id}`)
      .then((data) => setAnexos(data))
      .catch((err) => toast.error(err));
  }, [id]);

  const handleAddClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setNomeArquivo('');
    setArquivoUrl('');
    setNovoArquivo({ anexo: '' } as any);
  };

  const handleDeleteClick = (id: number) => () => {
    anexosActions.delete(id).then(() => {
      setAnexos((prevRows) => prevRows.filter((row) => row.id !== id));
    });
  };

  const handleDownloadClick = (arquivoLink: string) => () => {
    window.open(arquivoLink, '_blank');
  };

  const handleSaveAnexo = () => {
    const obj = {
      idContato: id,
      arquivoFile: novoArquivo.anexo,
    };
    anexosActions
      .createFormData(obj)
      .then((resp) => {
        setAnexos([...anexos, resp]);
        toast.success('Anexo salvo com sucesso!');
      })
      .catch((err) => toast.error(err))
      .finally(() => handleDialogClose());
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'arquivo', headerName: 'Anexo', minWidth: 350, flex: 1 },
    {
      field: 'dtCadastro',
      headerName: 'Data de Cadastro',
      width: 200,
      valueGetter: (value) => {
        return new Date(value).toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 100,
      cellClassName: 'actions',
      getActions: (props: any) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(props.id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DownloadIcon />}
          label="Download"
          onClick={handleDownloadClick(props.row.arquivoLink)}
          color="primary"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: 400,
          width: '100%',
        }}
      >
        <DataGrid
          rows={anexos}
          columns={columns}
          slots={{
            toolbar: EditToolbar as GridSlots['toolbar'],
          }}
          slotProps={{
            toolbar: { onAddClick: handleAddClick },
          }}
        />

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Adicionar Novo Anexo</DialogTitle>
          <DialogContent>
            <InputArquivo
              isEdit={false}
              newObj={novoArquivo}
              setNewObj={setNovoArquivo}
              nameFile={nomeArquivo}
              setNameFile={setNomeArquivo}
              urlFile={arquivoUrl}
              setUrlFile={setArquivoUrl}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleSaveAnexo} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Anexo;

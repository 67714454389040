import { IModulo } from './genericTypes';

export const MenuItens: IModulo[] = [
  {
    privilegio: '',
    icon: 'home',
    nome: 'Home',
    mostraFilhos: false,
    link: '/',
    linkHome: '/',
  },
  {
    privilegio: 'conteudo',
    icon: 'description',
    mostraFilhos: false,
    nome: 'Conteúdo',
    link: '',
    linkHome: '/conteudo',
    filhos: [
      {
        privilegio: 'areas',
        icon: 'arrow_right',
        nome: 'Menus',
        mostraFilhos: false,
        link: '/conteudo/areas',
      },
      {
        privilegio: 'secoes',
        icon: 'arrow_right',
        nome: 'Submenus',
        mostraFilhos: false,
        link: '/conteudo/secoes',
      },
      {
        privilegio: 'conteudo',
        icon: 'arrow_right',
        nome: 'Conteúdo',
        mostraFilhos: false,
        link: '/conteudo',
      },
      {
        privilegio: 'eventos',
        icon: 'arrow_right',
        nome: 'Eventos',
        mostraFilhos: false,
        link: '/conteudo/eventos',
      },
    ],
  },
  {
    privilegio: 'crm',
    icon: 'manage_accounts',
    nome: 'CRM',
    mostraFilhos: false,
    link: '',
    linkHome: '/crm',
    filhos: [
      {
        privilegio: 'Proposta de Programa',
        icon: 'arrow_right',
        nome: 'Proposta de Programa',
        mostraFilhos: false,
        link: '/crm/programas/novo',
      },
      {
        privilegio: 'empresas',
        icon: 'arrow_right',
        nome: 'Empresas',
        mostraFilhos: false,
        link: '/crm/empresa',
      },
      {
        privilegio: 'contatos',
        icon: 'arrow_right',
        nome: 'Contatos',
        mostraFilhos: false,
        link: '/crm/contatos',
      },
    ],
  },
  {
    privilegio: 'mesa de mercado',
    icon: 'folder_open',
    nome: 'Mesa de Mercado',
    mostraFilhos: false,
    link: '',
    linkHome: '/mesa-mercado',
    filhos: [
      {
        privilegio: 'empresa de relacionamento',
        icon: 'arrow_right',
        nome: 'Empresa de Relacionamento',
        mostraFilhos: false,
        link: '/empresas-relacionamento',
      },
      {
        privilegio: 'follow',
        icon: 'arrow_right',
        nome: 'Follow Up',
        mostraFilhos: false,
        link: '/follow-up',
      },
      {
        privilegio: 'mapa de clientes',
        icon: 'arrow_right',
        nome: 'Mapa de Clientes',
        mostraFilhos: false,
        link: '/mapa-de-clientes',
      },
    ],
  },
  {
    privilegio: 'log do sistema',
    icon: 'feed',
    nome: 'Log do Sistema',
    mostraFilhos: false,
    link: '/log-sistema',
    linkHome: '',
  },
  {
    privilegio: 'log do statogo',
    icon: 'feed',
    nome: 'Log do StatoGo',
    mostraFilhos: false,
    link: '/log-statoGo',
    linkHome: '',
  },
];

export const MenuItensHome: IModulo[] = [
  {
    privilegio: 'mesa de mercado',
    icon: 'folder_open',
    nome: 'Mesa de Mercado',
    mostraFilhos: false,
    link: '',
    linkHome: '/mesa-mercado',
    filhos: [],
  },
  {
    privilegio: 'cadastrodeusuario',
    icon: 'person_add_alt',
    mostraFilhos: false,
    nome: 'Cadastro de Usuário',
    link: '',
    linkHome: '/usuarios/usuarios',
    filhos: [],
  },
  {
    privilegio: 'crm',
    icon: 'ballot',
    nome: 'CRM',
    mostraFilhos: false,
    link: '',
    linkHome: '/crm/contatos',
    filhos: [],
  },
  {
    privilegio: 'conteudo',
    icon: 'description',
    mostraFilhos: false,
    nome: 'Conteúdo',
    link: '',
    linkHome: '/conteudo',
    filhos: [],
  },
  {
    privilegio: 'permissionamento',
    icon: 'key',
    mostraFilhos: false,
    nome: 'Permissionamento',
    link: '',
    linkHome: '/usuarios/usuarios',
    filhos: [],
  },
  {
    privilegio: 'ferramentasadministrativas',
    icon: 'settings',
    mostraFilhos: false,
    nome: 'Ferramentas Administrativas',
    link: '',
    linkHome: '/configuracoes-sistema/areas',
    filhos: [],
  },
  {
    privilegio: 'programas',
    icon: 'contacts',
    mostraFilhos: false,
    nome: 'Programas',
    link: '',
    linkHome: '/crm/programas/novo',
    filhos: [],
  },
];

export const ferramentasAdministrativas: IModulo[] = [
  {
    privilegio: 'ferramentasadministrativas',
    icon: 'settings',
    nome: 'Ferramentas Administrativas',
    mostraFilhos: false,
    link: '',
    linkHome: '',
    filhos: [
      {
        privilegio: 'programas',
        icon: 'arrow_right',
        nome: 'Programas',
        mostraFilhos: false,
        link: '/programas/etapas',
      },
      {
        privilegio: 'cadastrodeusuario',
        icon: 'arrow_right',
        nome: 'Cadastro de Usuário',
        mostraFilhos: false,
        link: '/usuarios/usuarios',
      },
      {
        privilegio: 'configuracaosistema',
        icon: 'arrow_right',
        nome: 'Configuração Sistema',
        mostraFilhos: false,
        link: '/configuracoes-sistema/areas',
      },
      {
        privilegio: 'permissionamento',
        icon: 'arrow_right',
        nome: 'Permissionamento',
        mostraFilhos: false,
        link: '',
      },
    ],
  },
];

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import { IVagaContato } from '../../../../commons/genericTypes/mercadoAlvo';
import { StatusCandidaturaEnum, TipoIndicacaoEnum } from '../../../../commons/enums';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { Autocomplete, Icon, TextField, Tooltip } from '@mui/material';
import { DivIcon } from './gestaoProcesso';
import { useEffect, useState } from 'react';
import { IContato } from '../../../../commons/genericTypes';
import { set } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { IParams } from '../../../CRM/MiniBio/Endereco';
import { toast } from 'react-toastify';
import { useVagaContatoActions } from '../../../../states/mesaDeMercado/vagaContatos/vaga-contato.actions';

const randomId = () => Math.floor(Math.random() * -10000);

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id: id,
        contatoId: 0,
        entrevista: false,
        realocado: false,
        status: StatusCandidaturaEnum.INDICADO,
        tipoIndicacao: TipoIndicacaoEnum.MANUAL,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [`${id}_0`]: { mode: GridRowModes.Edit, fieldToFocus: 'candidato' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Indicar Novo
      </Button>
    </GridToolbarContainer>
  );
}

export default function TabelaIndicado() {
  const params = useParams<IParams>();
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<IVagaContato[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [candidatoOptions, setCandidatoOptions] = useState<IContato[]>([]);
  const vagaContatoActions = useVagaContatoActions();

  useEffect(() => {
    vagaContatoActions
      .list(false, `?vagaId=${params.id}`)
      .then((resp: any) => {
        setRows(resp);
        vagaContatoActions.getContatos().then((contatoResp: any) => {
          const existingContatoIds = new Set(resp.map((row: IVagaContato) => row.contatoId));
          const filteredContatos = contatoResp.filter(
            (contato: IContato) => !existingContatoIds.has(contato.id),
          );
          setCandidatoOptions(filteredContatos);
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id?.toString() == id.toString().split('_')[0]);
    if (!editedRow!.id || editedRow!.id <= 0) {
      setRows(rows.filter((row) => row.id?.toString() != id.toString().split('_')[0]));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel<IVagaContato>) => {
    setIsSaving(true);
    const oldId = newRow.id;
    const updatedRow =
      newRow?.id! > 0 ? await updateVagaContato(newRow) : await createVagaContato(newRow);
    setRows(rows.map((row: any) => (row.id === oldId ? updatedRow : row)));
    setIsSaving(false);
    toast.success('Salvo com sucesso!');
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const createVagaContato = async (row: GridRowModel<IVagaContato>) => {
    let retorno = row;
    const obj = getVagaContato(row);
    const resp = await vagaContatoActions.create(obj);
    retorno.id = resp.id;
    return retorno;
  };

  const updateVagaContato = async (row: GridRowModel<IVagaContato>) => {
    let retorno = row;
    const obj = getVagaContato(row);
    const resp = await vagaContatoActions.update(obj.id!, obj);
    return retorno;
  };

  const getVagaContato = (row: GridRowModel<IVagaContato>) => {
    const obj: IVagaContato = {
      ...row,
      id: row.id! <= 0 ? undefined : row.id,
      contato: undefined,
      contatoId: getContatoId(row.contato),
      vagaId: Number(params.id),
      entrevista: row.entrevista,
      realocado: row.realocado,
      status: row.status,
    };
    return obj;
  };

  const getContatoId = (contato: any) => {
    if (typeof contato === 'string') return Number(contato.split(' ')[0]);
    else if (!!contato.id) return contato.id;
    else return contato.value.id;
  };

  const handleAprovarClick = async (id: GridRowId) => {
    await handleChangeStatus(id, StatusCandidaturaEnum.APROVADO);
  };

  const handleReprovarClick = async (id: GridRowId) => {
    const updatedRow = rows.find((row) => `${row.id}_${row.contatoId}` === id);
    if (!updatedRow?.motivoNegacao) {
      toast.warn('É necessário informar o motivo ao reprovar');
      return;
    }
    await handleChangeStatus(id, StatusCandidaturaEnum.REPROVADO);
  };

  const handleChangeStatus = async (id: GridRowId, status: StatusCandidaturaEnum) => {
    setIsSaving(true);
    try {
      const updatedRow = rows.find((row) => `${row.id}_${row.contatoId}` === id);
      if (updatedRow) {
        const newRow = { ...updatedRow, status };
        await processRowUpdate(newRow);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'tipoIndicacao',
      headerName: 'Inclusão',
      width: 80,
      editable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {params.row.tipoIndicacao === TipoIndicacaoEnum.AUTOMATICA ? (
              <DivIcon>
                <Icon>filter_alt</Icon>
              </DivIcon>
            ) : (
              <DivIcon>
                <Icon>person</Icon>
              </DivIcon>
            )}
          </div>
        );
      },
    },
    {
      field: 'contato',
      headerName: 'Candidato',
      type: 'string',
      width: 180,
      editable: true,
      renderEditCell: (params) => (
        <>
          <Autocomplete
            options={candidatoOptions.map((option) => ({
              label: `${option.id} - ${option.nome}`,
              value: option,
            }))}
            value={params.value}
            onChange={(event, newValue) => {
              params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Candidato" variant="standard" />}
            isOptionEqualToValue={(option, value) => option.value.id === value?.id}
            disabled={params.row.id > 0}
          />
        </>
      ),
      valueGetter: (params: any, row: any) => {
        return params?.id ? `${params.id} - ${params.nome}` : `${params?.label ?? (params || '')}`;
      },
    },
    {
      field: 'defesa',
      headerName: 'Defesa',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'motivoNegacao',
      headerName: 'Motivo Negação',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'status',
      sortable: true,
      type: 'actions',
      headerName: 'Aprovar/Reprovar',
      width: 140,
      getActions: ({ id, row }) => {
        if (row.status === StatusCandidaturaEnum.INDICADO) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Aprovar">
                  <ThumbUpIcon />
                </Tooltip>
              }
              label="Aprovar"
              onClick={() => handleAprovarClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Reprovar">
                  <ThumbDownIcon />
                </Tooltip>
              }
              label="Reprovar"
              onClick={() => handleReprovarClick(id)}
              color="secondary"
            />,
          ];
        } else if (row.status === StatusCandidaturaEnum.APROVADO) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Aprovado">
                  <ThumbUpIcon sx={{ color: 'green' }} />
                </Tooltip>
              }
              label="Aprovado"
              color="inherit"
            />,
          ];
        } else if (row.status === StatusCandidaturaEnum.REPROVADO) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Reprovado">
                  <ThumbDownIcon sx={{ color: 'red' }} />
                </Tooltip>
              }
              label="Reprovado"
              color="inherit"
            />,
          ];
        }
        return [];
      },
    },
    {
      field: 'aceito',
      headerName: 'Aceito',
      type: 'boolean',
      width: 100,
      editable: true,
    },
    {
      field: 'entrevista',
      headerName: 'Entrevista',
      type: 'boolean',
      width: 100,
      editable: true,
    },
    {
      field: 'recolocado',
      headerName: 'Recolocado',
      type: 'boolean',
      width: 100,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              disabled={isSaving}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={isSaving}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          // icon={<DeleteIcon />}
          // label="Delete"
          // onClick={handleDeleteClick(id)}
          // color="inherit"
          // />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => `${row.id}_${row.contatoId}`}
        loading={loading}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}

import { styled } from 'styled-components';
import { ButtonStato, GridContainer } from '../../../commons/styleds';
import {
  Box,
  Checkbox,
  Grid,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Stepper,
  StepLabel,
  Step,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { history } from '../../../helpers/history';
import HeaderPages from '../../../components/Generics/HeaderPages';
import { IConteudo, ISecao, ITbArea, IdNome } from '../../../commons/genericTypes';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { useTbAreaActions } from '../../../states/conteudos/tbAreas/tbArea.actions';
import { useSecaoActions } from '../../../states/conteudos/secoes/secao.actions';
import { useConteudoActions } from '../../../states/conteudos/conteudos/conteudo.actions';
import ConteudoStepper1 from './Steps/stepper1';
import ConteudoStepper2 from './Steps/stepper2';
import ConteudoStepper3 from './Steps/stepper3';
import React from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

const empty: IConteudo = {
  id: 0,
  // nome: '',
  idAreas: [],
  anexoFile: undefined,
  areas: [],
  anexo: '',
  dtPublicacao: undefined,
  idSecaos: [],
  secaos: [],
  visivel: true,
  ativo: true,
};

const steps = ['', '', ''];

const Conteudos: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const conteudoActions = useConteudoActions();
  const tbAreaActions = useTbAreaActions();
  const secaoActions = useSecaoActions();

  const [nomeArquivo, setNomeArquivo] = useState('');
  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    params.row.descricao = params.row.descricao ?? '';
    setEditavel(params.row.anexo != '' ? true : false);
    setNomeArquivo(params.row.anexo);
    atualizarFormulario(params.row);
  };

  const [novoConteudo, setNovoConteudo] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [editavel, setEditavel] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [conteudos, setConteudos] = useState<IConteudo[]>([]);
  const [conteudoTemp, setConteudoTemp] = useState<IConteudo>();
  const [areasSelect, setAreasSelect] = useState<ITbArea[] | undefined>(undefined);
  const [secoesSelect, setSecoesSelect] = useState<ISecao[] | undefined>(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepSave, setActiveStepSave] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [arquivoUrl, setArquivoUrl] = useState('');
  const [arquivoUrl2, setArquivoUrl2] = useState('');

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'areas',
      headerName: 'Areas',
      width: 150,
      valueGetter: (params: GridCellParams, row: any) => {
        if (row.idAreas == undefined) return '';
        const areas = row.idAreas as ITbArea[];
        const descricoes = areas.map((area) => area.nome);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      },
    },
    {
      field: 'secaos',
      headerName: 'Seções',
      width: 150,
      valueGetter: (params: GridCellParams, row: any) => {
        if (row.idSecaos == undefined) return '';
        const secaos = row.idSecaos as ISecao[];
        const descricoes = secaos.map((secao) => secao.nome);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      },
    },
    {
      field: 'titulo',
      headerName: 'Titulo',
      width: 150,
    },
    {
      field: 'resumo',
      headerName: 'Resumo',
      width: 150,
    },
    {
      field: 'texto',
      headerName: 'Texto',
      width: 150,
    },
    {
      field: 'dtPublicacao',
      headerName: 'Data Publicação',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IConteudo;
        return new Date(obj.dtPublicacao!).toLocaleDateString();
      },
    },
    {
      field: 'origem',
      headerName: 'Origem',
      width: 150,
    },
    {
      field: 'ordem',
      headerName: 'Posição',
      width: 100,
    },
    {
      field: 'visivel',
      headerName: 'Visivel Portal Assessorado',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IConteudo;
        return <Checkbox checked={obj.visivel} inputProps={{ 'aria-label': 'controlled' }} />;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IConteudo;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const deleteValue = () => {
    conteudoActions
      .delete(conteudoTemp!.id!)
      .then((resp: IConteudo[]) => {
        const sub = conteudos.filter((f) => f.id !== conteudoTemp!.id);
        setConteudos(sub);
        atualizarFormulario(empty);
        toast.success('Apagado com sucesso !');

        methods.reset(conteudoTemp, { keepDefaultValues: true });
        setIdAtualizar(conteudoTemp!.id);
        setLoadingForm(true);
        setOpen(false);
        setTimeout(() => {
          setLoadingForm(false);
        }, 1500);
      })
      .catch((err: any) => toast.warn(err));
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep < 2) setActiveStep(activeStep + 1);
    else setActiveStepSave(1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const cancelar = () => {
    setActiveStep(0);
    atualizarFormulario(empty);
    setEditavel(false);
    setNomeArquivo('');
  };

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <ConteudoStepper1
            novoConteudo={novoConteudo}
            tbAreas={areasSelect!}
            tbSecoes={secoesSelect!}
          />
        );
      case 1:
        return <ConteudoStepper2 />;
      case 2:
        return (
          <ConteudoStepper3
            editAcao={editavel}
            arquivoUrl={arquivoUrl2}
            setArquivoUrl={setArquivoUrl}
            setNomeArquivo={setNomeArquivo}
            nomeArquivo={nomeArquivo}
          />
        );
      default:
        break;
    }
  };

  const AtualizarUsuario = (data: any) => {
    conteudoActions
      .update(data.id, data)
      .then((result: IConteudo) => {
        const sub = conteudos.filter((f) => f.id !== result.id);
        setConteudos(sub.concat(result));
        atualizarFormulario(empty);
        setActiveStep(0);
        setActiveStepSave(0);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };

  const onSubmit = async (data: any) => {
    if (activeStepSave == 0) return;

    setLoadingSave(true);
    if (data.areas != undefined)
      data.idAreas = areasSelect?.filter((area) => data.areas.includes(area.id));
    if (data.secaos != undefined)
      data.idSecaos = secoesSelect?.filter((secao) => data.secaos.includes(secao.id));

    if (data.id > 0) AtualizarUsuario(data);
    else {
      conteudoActions
        .createFormData(data)
        .then((result: IConteudo) => {
          setConteudos(conteudos.concat(result));
          atualizarFormulario(empty);
          setActiveStep(0);
          setActiveStepSave(0);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          setActiveStepSave(0);
          toast.error('Erro ao Cadastrar!');
        });
    }

    setLoadingSave(false);
  };

  useEffect(() => {
    conteudoActions
      .list()
      .then((resp: IConteudo[]) => setConteudos(resp))
      .catch((err: any) => toast.warn(err));

    tbAreaActions
      .list()
      .then((resp: ITbArea[]) => setAreasSelect(resp))
      .catch((err: any) => toast.warn(err));

    secaoActions
      .list()
      .then((resp: ISecao[]) => setSecoesSelect(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    result.dtPublicacao = dayjs.tz(result.dtPublicacao);

    if (result.idAreas != undefined && result.idAreas.length > 0)
      result.areas = result.idAreas.map((m: IdNome) => m.id);

    if (result.idSecaos != undefined && result.idSecaos.length > 0)
      result.secaos = result.idSecaos.map((m: IdNome) => m.id);

    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setConteudoTemp(obj);
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
  };

  return (
    <BasePage>
      <HeaderPages
        actionBack={false}
        arrowBackClick="/crm/contatos"
        icon="ballot_icon"
        title="Conteudos"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id="formConteudo" onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>{idAtualizar ? `Atualizar - ID: ${idAtualizar}` : 'Novo Cadastro'}</h3>

                <Stepper activeStep={activeStep} sx={{ marginTop: '5px' }}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <React.Fragment>
                  {getStep()}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <ButtonStato
                      color="inherit"
                      variant="contained"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Voltar
                    </ButtonStato>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {idAtualizar! > 0 ? (
                      <ButtonStato
                        color="error"
                        variant="contained"
                        sx={{ marginRight: '10px' }}
                        onClick={cancelar}
                      >
                        {'Cancelar'}
                      </ButtonStato>
                    ) : (
                      <></>
                    )}
                    {activeStep >= 2 ? (
                      <ButtonStato type="submit" onClick={handleNext} variant="contained">
                        {'Salvar'}
                      </ButtonStato>
                    ) : (
                      <ButtonStato variant="contained" onClick={handleNext}>
                        {'Próximo'}
                      </ButtonStato>
                    )}
                  </Box>
                </React.Fragment>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={conteudos}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 10px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

export default Conteudos;

import { withRouter } from 'react-router-dom';
import Logo from '../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-2 Branco.png';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { GridContainer, LogoGridItem } from '../../commons/styleds';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../helpers/history';
import { AppBar, Drawer, DrawerHeader, BoxMenu } from './MenuStyle';
import { MenuItens, ferramentasAdministrativas } from '../../commons/menuItens';
import React from 'react';
import Icon from '@mui/material/Icon';
import ExpandMore from '@mui/icons-material/ExpandLess';
import ExpandLess from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from 'styled-components';
import { useUsuarioActions } from '../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { IModulo } from '../../commons/genericTypes';
import useTheme from '@mui/material/styles/useTheme';
import BuscaContatos from '../Busca/buscaContatos';

const MenuBar: React.FC = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { children } = props;
  const [open, setOpen] = useState(false);
  const usuarioActions = useUsuarioActions();
  const auth = useRecoilValue(authAtom);
  const theme = useTheme();
  const [menuItens, setMenuItens] = useState<IModulo[]>([]);
  const [menuItenFilhosAbertos, setMenuItenFilhosAbertos] = React.useState<string>('');

  useEffect(() => {
    if (!auth || !auth.token) {
      logout();
    }
    const modulos = auth?.modulos?.map((m) => m.modulo);
    const filterMenu = MenuItens.filter(
      (m) => modulos?.includes(m.privilegio) || auth?.administrador || !m.privilegio,
    );
    setMenuItens(filterMenu);
  }, [auth]);

  const handleClickDrawer = () => {
    if (open) setMenuItenFilhosAbertos('');
    setOpen(!open);
  };

  const handleClickListItemButton = (menuItem: IModulo) => {
    if (menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0) {
      let list = menuItenFilhosAbertos.split(',');
      setMenuItenFilhosAbertos(list.filter((f) => f !== menuItem.privilegio).toString());
    } else {
      setMenuItenFilhosAbertos(',' + menuItem.privilegio);
    }
    if (menuItem.filhos!.length > 0 && menuItenFilhosAbertos.split(',').length > 0) {
      setOpen(true);
    }
  };

  const setHistory = (link: string) => {
    history.push(link);
    handleClickDrawer();
    setMenuItenFilhosAbertos('');
  };

  const logout = () => {
    usuarioActions.logout();
  };

  return (
    <GridContainer>
      <Box>
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleClickDrawer}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Stato Intoo
              </Typography>
            </div>

            <div style={{ display: 'flex' }}>
              <DivBusca>
                <BuscaContatos />
              </DivBusca>

              <BoxOcultarMobile>
                <Button
                  style={{ color: 'white', fontSize: '18px' }}
                  id="basic-button"
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Olá, {auth?.nome}
                  {openMenu === false ? (
                    <KeyboardArrowDownIcon
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      style={{
                        position: 'relative',
                        left: '5px',
                      }}
                    />
                  )}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose}>Minha Conta</MenuItem>
                  <MenuItem onClick={logout}>Sair</MenuItem>
                </Menu>
              </BoxOcultarMobile>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DivStatoVerde>
            <DrawerHeader
              style={
                !open
                  ? { backgroundImage: 'transparent !important' }
                  : {
                      backgroundImage: 'transparent !important',
                      minHeight: '130px',
                    }
              }
            >
              {open ? (
                <LogoGridItem item xs={12} md={6} style={{ height: '130px' }}>
                  {' '}
                  <img src={Logo} alt="logo" width={'90%'} />
                </LogoGridItem>
              ) : (
                <></>
              )}
              <IconButton sx={{ color: 'white' }} onClick={handleClickDrawer} hidden={true}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />

            <List>
              {menuItens.map((menuItem, index) => (
                <ListItem key={menuItem.nome} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() =>
                      !menuItem.filhos || menuItem.filhos.length === 0
                        ? setHistory(menuItem.link as string)
                        : handleClickListItemButton(menuItem)
                    }
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      <Icon style={{ fontSize: 25 }}>{menuItem.icon}</Icon>
                    </ListItemIcon>
                    <MenuItemStato primary={menuItem.nome} open={open} />
                    {menuItem.filhos !== undefined &&
                      menuItem.filhos!.length > 0 &&
                      open &&
                      (menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </ListItemButton>
                  <Collapse
                    in={menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menuItem.filhos !== undefined &&
                        menuItem.filhos!.length > 0 &&
                        menuItem.filhos!.map((menuItemFilho: any, index: number) => (
                          <ListItemButton
                            key={index}
                            sx={{ pl: 4 }}
                            onClick={() =>
                              setHistory(
                                menuItem.link
                                  ? `${menuItem.link}/${menuItemFilho.link}`
                                  : `${menuItemFilho.link}`,
                              )
                            }
                          >
                            <ListItemIcon sx={{ color: 'white' }}>
                              <Icon style={{ fontSize: 30 }}>{menuItemFilho.icon}</Icon>
                            </ListItemIcon>
                            <MenuItemStato open={open} primary={menuItemFilho.nome} />
                          </ListItemButton>
                        ))}
                    </List>
                  </Collapse>
                </ListItem>
              ))}
            </List>

            <Grid style={{ position: 'sticky', top: '88vh' }}>
              {ferramentasAdministrativas.map((menuItem: any, index: number) => (
                <ListItem key={menuItem.nome} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() =>
                      menuItem.link !== ''
                        ? setHistory(menuItem.link as string)
                        : handleClickListItemButton(menuItem)
                    }
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      <Icon style={{ fontSize: 25 }}>{menuItem.icon}</Icon>
                    </ListItemIcon>
                    <MenuItemStato
                      open={open}
                      primary={menuItem.nome}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {menuItem.filhos !== undefined &&
                      menuItem.filhos!.length > 0 &&
                      open &&
                      (menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </ListItemButton>
                  <Collapse
                    in={menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menuItem.filhos !== undefined &&
                        menuItem.filhos!.length > 0 &&
                        menuItem.filhos!.map((menuItemFilho: any, index: number) => (
                          <ListItemButton
                            key={index}
                            sx={{ pl: 4 }}
                            onClick={() => setHistory(menuItemFilho.link as string)}
                          >
                            <ListItemIcon sx={{ color: 'white' }}>
                              <Icon style={{ fontSize: 30 }}>{menuItemFilho.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={menuItemFilho.nome} />
                          </ListItemButton>
                        ))}
                    </List>
                  </Collapse>
                </ListItem>
              ))}
            </Grid>
          </DivStatoVerde>
        </Drawer>
      </Box>
      <BoxMenu open={open} component="main">
        <GridConteudoPrincipal container>{children}</GridConteudoPrincipal>
      </BoxMenu>
    </GridContainer>
  );
};

const GridConteudoPrincipal = styled(Grid)`
  display: block !important;
`;

const BoxOcultarMobile = styled(Box)`
  // position: fixed;
  right: 50px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const DivBusca = styled.div`
  // position: fixed;
  right: 200px;
  justify-content: flex-end;
`;

const MenuItemStato = styled(ListItemText)<{ open: boolean }>`
  max-width: 172px;
  text-wrap: ${(p) => (p.open ? 'wrap' : 'no')};
  opacity: ${(p) => (p.open ? 1 : 0)};
`;

const DivStatoVerde = styled.div`
  min-height: 100vh;
  color: white !important;
  background: linear-gradient(
      var(--green1) 0%,
      var(--green2) 3%,
      var(--menuTopLeft) 20%,
      rgba(17, 139, 215, 0) 40%,
      var(--menuBottomLeft) 60%,
      var(--green2) 95%,
      var(--green1) 100%
    ),
    linear-gradient(90deg, var(--green3) 0%, var(--blue3) 60%);
  overflow-y: auto;
  overflow-x: hidden;
`;

export default withRouter(MenuBar);

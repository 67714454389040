import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Icon,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IVaga } from '../../../commons/genericTypes/mercadoAlvo';
import { ButtonStato, GridTopPage, IconTituloStato } from '../../../commons/styleds';
import { usePermissions } from '../../../components/PermissionsProvider';
import { history } from '../../../helpers/history';
import { useVagaActions } from '../../../states/mesaDeMercado/vagas/vaga.actions';
import { BasePage, Container } from '../../CRM/Empresa';
import { createColumns } from './columns';
import dayjs from 'dayjs';

const VagasFollowUp: React.FC = () => {
  const actions = useVagaActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [idDeletar, setIdDeletar] = useState<number>();
  const [open, setOpen] = useState(false);
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50000);
  const [rows, setRows] = useState<IVaga[]>([]);
  const [rowCount, setRowCount] = useState<number>(50000);

  const { podeEditar, podeExcluir } = usePermissions();
  const modulo = 'mesa de mercado';
  const canExclude = podeExcluir(modulo);
  const canEdit = podeEditar(modulo);

  useEffect(() => {
    let query = `?pagina=1&tamanhoPagina=50000`;
    if (dataInicio) query += `&dataInicio=${dataInicio}`;
    if (dataFim) query += `&dataFim=${dataFim}`;

    actions
      .list(false, query)
      .then((res) => {
        setRows(res.itens);
        setRowCount(res.totalItens);
        setLoading(false);
      })
      .catch((err) => toast.error('Erro ao buscar vagas.'));
  }, [page, pageSize]);

  const handleEditar = (obj: any) => {
    history.push(`/follow-up/${obj.id}`);
  };
  const handleDeletar = (obj: any) => {
    setIdDeletar(obj.id);
    setOpen(true);
  };
  const columns = createColumns({ canExclude, handleEditar, handleDeletar });

  const handleClose = () => {
    setOpen(false);
  };

  const deleteValue = () => {};

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    handleEditar(params.row);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Vagas Relacionamento</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/follow-up/nova')}
            type="submit"
            variant="contained"
          >
            <Icon>add</Icon>
            Adicionar Vaga
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={2} md={3}>
            <Typography>Intervalo da Pesquisas:</Typography>
          </Grid>
          <Grid item xs={5} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{
                clearButtonLabel: 'Empty',
                todayButtonLabel: 'Now',
              }}
            >
              <DesktopDatePicker
                value={dayjs(dataInicio)}
                onChange={(value) => setDataInicio(value!.toDate() as Date)}
                label="Data Início"
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={5} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{
                clearButtonLabel: 'Empty',
                todayButtonLabel: 'Now',
              }}
            >
              <DesktopDatePicker
                value={dayjs(dataFim)}
                onChange={(value) => setDataFim(value!.toDate() as Date)}
                label="Data Fim"
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id!}
                onRowClick={handleRowClick}
                rows={rows}
                columns={columns}
                pagination
                rowCount={rowCount}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

export default VagasFollowUp;

import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';

export function useVagaContatoActions() {
  const axiosWrapper = useAxiosWrapper();
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/vagaContatos`;
  const baseActions = useBaseActions('vagaContatos');

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    getContatos: getContatos,
  };

  async function getContatos() {
    let url = `${baseUrl}/contatos`;
    const recursos = await axiosWrapper.get(url);

    return recursos;
  }
}
